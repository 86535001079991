<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            default="today"
            type="year"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <c-tab
      type="tabcard"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
    >
      <!-- setHeight="500px" -->
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :searchParam.sync="searchParam"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'inoutStatisticalInvestigation',
  props: {
  },
  data() {
    return {
      tab: 'statistics',
      tabItems: [],
      searchParam: {
        plantCd: null,
        year: '',
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        // 통계조사표
        { name: 'statistics', icon: 'trending_up', label: '통계조사표', component: () => import(`${'./statistics.vue'}`) },
        // 배출량조사표
        { name: 'emissions', icon: 'factory', label: '배출량조사표', component: () => import(`${'./emissions.vue'}`) },
      ]
    },

  }
};
</script>